:root {
	--green: #b4d3b2;
	--red: #d4371c;
	--darkRed: #811b09;
	--lightGray: #25252c;
	--darkGray: #363646;
}

.commentSection-container {
	height: calc(100vh - 25vh);
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
	background-color: var(--lightGray);
}

.comments-container {
	background-color: var(--lightGray);
	height: 70%;
	border-color: var(--darkRed);
	border-radius: 20px;
	overflow-y: scroll;
	width: 80%;
}
.comment-input-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 40%;
}
.comment-input {
	background-color: var(--lightGray);
	border-color: var(--red);
	border-radius: 20px;
	border-style: solid;
	color: white;
	width: 90%;
	height: 90px;
}

.comment-post-button {
	background-color: var(--red);
	color: white;
	border: none;
	margin-left: 20px;
}
.post-details {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.comment-input:focus-visible {
}
.empty-comment-section {
	color: var(--darkGray);
	font-size: 50px;
	padding-top: 15%;
}

@media screen and (max-width: 897px) {
	.comments-container {
		width: 85vw;
	}

	textarea.comment-input {
		width: 400px;
	}
}
