#about-header {
	color: white;
	font-size: 50px;
	/* margin-left: 2em; */
	margin-top: 40px;
	margin-bottom: 20px;
	width: 100%;
	text-align: center;
}
#about-content {
	color: white;
	/* margin-left: 5em; */
	font-size: 20px;
	margin-top: 0;
	display: flex;
	flex-direction: column;
	width: 1000px;
}
#about-container {
	padding: 3em;
	background-color: #18191a;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	height: calc(100vh - 15vh);
	overflow-y: scroll;
	margin-left: 20px;
	margin-right: 20px;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
#about-container::-webkit-scrollbar {
	display: none;
}

#about-list {
	line-height: 45px;
}

img.about-icon {
	height: 100px;
	width: 100px;
	background-color: var(--darkRed);
	margin-right: 25px;
	margin-bottom: 10px;
	margin-top: 10px;
}

.about-bullet {
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	align-items: center;
}

.howto-bullet {
	margin-bottom: 10px;
}

.criteria-content {
	color: white;
	/* margin-left: 5em; */
	font-size: 20px;
	margin-top: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
}
