:root {
	--green: #b4d3b2;
	--red: #d4371c;
	--darkRed: #811b09;
	--lightGray: #25252c;
	--darkGray: #363646;
}

#changelog-header {
	color: white;
	font-size: 30px;
	/* margin-left: 2em; */
	margin-bottom: 0.2em;
	margin-top: 0;
	font-weight: bold;
}
#changelog-item {
	border-bottom: 1px solid white;
	margin-bottom: 20px;
	padding-left: 30px;
}
#changelog-content {
	color: white;
	/* margin-left: 5em; */
	font-size: 20px;
	margin-top: 0;
	margin-bottom: 20px;
}
#changelog-container {
	/* height: 750px; */
	padding: 3em;
	background-color: var(--lightGray);
	justify-content: center;
	border-radius: 20px;
	height: calc(100vh - 25vh);
	overflow-y: scroll;
}

#changelog-list {
	line-height: 45px;
}
