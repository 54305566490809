:root {
	--green: #b4d3b2;
	--red: #d4371c;
	--darkRed: #811b09;
	--lightGray: #25252c;
	--darkGray: #363646;
}

.table {
	margin-right: 20px;
	margin-left: 20px;
}

.table-expanded {
	position: absolute;
	margin: 0;
	padding: 0;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	/* overflow: hidden; */
	z-index: 100;
}
.invisible-title-box {
	width: 80px;
}

.title-name-button {
	background-color: var(--darkGray);
	color: white;
	font-weight: 900;
	font-size: 30px;
	border-radius: 48px;
}
.ant-table-wrapper .ant-table-tbody > tr > td {
	padding: 16px;
}
.ant-table-wrapper .ant-table-thead > tr > th {
	padding-top: 0px;
	padding-bottom: 0px;
}
.table-title {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.table-titlename {
	color: white;
	justify-self: center;
}
.title-button-container {
	display: flex;
	flex-direction: row;
	justify-self: flex-end;
}
.title-button {
	padding-top: 8px;
	margin-top: 0px;
	margin-bottom: 7px;
	width: 80px;
	font-size: 12px;
	height: 30px;
	background-color: var(--darkGray);
}
.score-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.score-arrow {
	width: 20px;
	height: 10px;
	font-size: 20px;
	margin-right: 5px;
	margin-left: 5px;
	cursor: pointer;
}
.active {
	color: var(--red);
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
	background-color: none;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
	background: none;
}

.ant-table-column-sorter {
	display: none;
}
.ant-table-wrapper
	.ant-table-thead
	th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover {
	background: var(--darkRed);
}
.ant-table-wrapper
	.ant-table-thead
	th.ant-table-column-has-sorters.ant-table-cell-fix-left {
	background: var(--darkRed);
}
.ant-notification-notice .ant-notification-notice-message {
	color: white;
}
.ant-notification-notice .ant-notification-notice-close {
	display: none;
}
.notification {
	background-color: #25252c;
	color: white;
	width: 250px;
	height: 70px;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
	border-color: var(--red);
	background-color: var(--red);
}

.ant-radio-wrapper {
	color: white;
	font-weight: 600;
}
.ant-upload-wrapper
	.ant-upload-list
	.ant-upload-list-item
	.ant-upload-list-item-name {
	display: none;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container {
	height: 0;
	transition: none;
}
.ant-modal .ant-modal-title {
	color: white;
}
.ant-modal .ant-modal-header {
	background-color: var(--lightGray);
}
.ant-table-wrapper .ant-table {
	background-color: var(--darkRed);
	border-radius: 48px;
}
.ant-input:focus {
	border-color: var(--darkRed);
}
.ant-input:hover {
	border-color: var(--darkRed);
}
.ant-input:focus-visible {
	border-color: var(--darkRed);
}
.ant-modal .ant-modal-content {
	background-color: var(--lightGray);
	width: 400px;
}
.ant-btn-primary {
	background-color: var(--darkRed);
}
.ant-btn-primary:not(:disabled):hover {
	background-color: var(--red);
}
.ant-popconfirm .ant-popconfirm-message-title {
	color: white;
}
.ant-table-wrapper .ant-table-title {
	padding-bottom: 0;
}
.ant-btn-default:not(:disabled) {
	background-color: var(--lightGray);
	border-color: white;
	color: white;
}
.ant-btn-default:not(:disabled):hover {
	color: var(--red);
	border-color: var(--red);
}
.ant-popover .ant-popover-inner {
	background-color: var(--lightGray);
	color: white;
}
div.ant-table-title {
	background-color: #811b09;
	display: flex;
	justify-content: center;
	color: white;
	font-weight: 900;
	font-size: 30px;
	border-radius: 48px;
}
.ant-table-wrapper .ant-table-thead > tr > td {
	background-color: var(--darkRed);
}
img.header-icon {
	height: 50px;
	width: 50px;
}

.table__container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.logIn__button {
	background-color: var(--red);
}

.check {
	color: var(--green);
	font-size: 20px;
}

.uncheck {
	color: var(--red);
	font-size: 20px;
}

.editable-row {
	background-color: var(--darkGray);
	color: white;
	font-weight: 600;
	text-align: center;
	max-height: 57px;
}

.ant-table .ant-table-container .ant-table-content table,
th.ant-table-cell,
td.ant-table-cell {
	border: none;
	border-collapse: collapse;
}

/* .ant-table-tbody .ant-table-row:nth-child(even) {
    background-color: #55575a;
  } */
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
	background-color: #55575a;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row {
	max-height: 57px;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-expandable-row {
	z-index: 1;
}

.ant-table
	.ant-table-container
	.ant-table-content
	table
	thead.ant-table-thead
	.ant-table-cell {
	background-color: var(--darkRed);
	text-align: center;
	border: none;
}

.ant-table-wrapper .ant-table-thead > tr > th {
	background-color: var(--darkRed);
	color: white;
	font-weight: 900;
	text-align: center;
}

.ant-table .ant-table-container {
	background-color: var(--darkGray);
	text-align: center;
	border: none;
}
.ant-table .ant-table-container .ant-table-content table {
	background-color: var(--darkGray);
	text-align: center;
	border: none;
}

a.ant-typography {
	color: var(--red);
}

a {
	color: var(--red);
}
.ant-table-wrapper .ant-table .ant-table-header {
	background-color: var(--darkRed);
	border-radius: 0;
}
a.ant-typography:hover {
	color: #60a65b;
}

.ant-checkbox .ant-checkbox-inner:focus {
	border-color: var(--red);
}
.ant-checkbox-checked .ant-checkbox-inner {
	background-color: var(--red);
	border-color: var(--red);
}

.ant-checkbox-checked:focus .ant-checkbox-inner {
	background-color: var(--red);
	border-color: var(--red);
}

.ant-checkbox-disabled .ant-checkbox-inner {
	background-color: var(--red);
	border-color: var(--red);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
	.ant-checkbox-checked:not(.ant-checkbox-disabled)
	.ant-checkbox-inner {
	background-color: #60a65b;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
	.ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
	border-color: #60a65b;
}

.ant-input:hover {
	border-color: #60a65b;
}

.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td {
	background-color: var(--darkGray);
	color: white;
	font-weight: 600;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td {
	background-color: var(--lightGray);
}

p.fa {
	color: white;
	font-weight: 800;
	margin: 30px;
	margin-bottom: 0;
	text-align: left;
	max-width: 500px;
}
.description-container .description {
	color: white;
	font-weight: 800;
	margin: 30px;
	margin-top: 10px;
	text-align: left;
	max-width: 500px;
}
.positioner {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	/* align-items: center; */
}
.private {
	font-size: 20px;
}
input.private-check {
	margin-top: 10px;
	margin-right: 10px;
}
input:checked .private-check:checked {
	background-color: #60a65b;
}
.ant-table-wrapper .ant-table-filter-trigger:hover {
	background: none;
}
@media screen and (min-width: 897px) {
	.positioner {
		justify-content: space-evenly;
		align-items: center;
	}
}
/* .expanded-content-container {
	top: 0;
	position: relative;
	width: 100%;
} */
/* 
div.expanded-row-video {
	top: 50%;
} */

@media screen and (max-width: 897px) {
	div.ant-table-title {
		font-size: 20px;
	}

	.ant-table-wrapper .ant-table-tbody > tr > td {
		padding: 5px;
		font-size: 12px;
	}
	.ant-table-wrapper .ant-table-thead > tr > th {
		padding: 1px;
		/* font-size: 12px; */
	}
	.positioner {
		width: 100%;
		position: relative;
		/* position: sticky; */
		flex-direction: column;
		/* align-content: flex-start; */
	}
	.expanded-content-container {
		top: 0;
		position: relative;
		width: 100%;
	}
	div.expanded-row-video {
		padding-top: 10px;
		position: sticky;
		width: calc(100vw - 10vw);
		left: 0;
		padding-left: 4.5vw;
		padding-right: 4.5vw;
		/* padding-right: px; */
		margin: 0;
	}

	div.description-container {
		position: sticky;
		width: calc(100vw - 10vw);
		left: 0;
		margin: 0;
		padding-left: 4.5vw;
		padding-right: 5vw;
		padding-bottom: 10px;
	}
	.description-container p.fa {
		margin: 0;
		padding-top: 20px;
		padding-bottom: 20px;
		font-size: 12px;
	}
	.description-container p.description {
		margin: 0;
		font-size: 12px;
	}
	.table {
		margin: 5px;
	}
}
