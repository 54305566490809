.row-commentSection-container {
	height: calc(100vh - 25vh);
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	width: 40%;
}

.row-commentSection-container .comments-container {
	background-color: var(--lightGray);
	height: 70%;
	border-color: var(--darkRed);
	border-radius: 20px;
	overflow-y: scroll;
	width: 100%;
	margin-right: 20px;
}
.row-commentSection-container .comment-input-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.row-commentSection-container .comment-input {
	background-color: var(--lightGray);
	border-color: var(--red);
	border-radius: 20px;
	border-style: solid;
	color: white;
	width: 100%;
	height: 90px;
}

.row-commentSection-container .comment-post-button {
	background-color: var(--red);
	color: white;
	border: none;
	margin-left: 20px;
}
.row-commentSection-container .post-details {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.comment-input:focus-visible {
}
.row-commentSection-container .empty-comment-section {
	color: var(--darkGray);
	font-size: 20px;
	padding: 15%;
	text-align: center;
}

@media screen and (max-width: 897px) {
	.row-commentSection-container .comments-container {
		width: 85vw;
	}

	.row-commentSection-container textarea.comment-input {
		width: 400px;
	}
}
