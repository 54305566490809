.expanded-row-video {
	align-self: center;
}

.positioner-left {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-top: 20px;
}
