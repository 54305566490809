.community-container {
	padding-top: 30px;
	background-color: #181a1e;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-radius: 20px;
	height: calc(100vh - 15vh);
	width: 100%;
	margin-left: 20px;
	margin-right: 20px;
	padding: 30px;
	overflow: scroll;
}

.table-list-container {
	width: 100%;
}

.table-list {
	/* display: flex;
	flex-direction: column; */
	width: 100%;
	height: 100%;
	/* justify-content: center; */

	overflow: scroll;
}

.community-link {
	background-color: var(--lightGray);
	height: 50px;
	line-height: 50px;
	margin: 10px;
	border-radius: 20px;
}

.community-row {
	padding-left: 40px;

	width: 100%;
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
	font-size: 20px;
}
.table-list-container-title {
	color: white;
	margin-left: 50px;
	margin-bottom: 30px;
}

.mini__table__container {
	margin-right: 10px;
	margin-left: 10px;
	width: 300px;
	height: 450px;
	position: relative;
	display: inline-block;
	/* white-space: wrap; */
}

.underConstruction {
	font-size: 150px;
	color: #28303f;
	margin-bottom: 100px;
	text-align: center;
	margin: 40px;
}
