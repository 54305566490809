:root {
	--green: #b4d3b2;
	--red: #d4371c;
	--darkRed: #811b09;
	--lightGray: #55575a;
	--darkGray: #3a3b3c;
	--darkestGray: #242526;
	--light: #181a1e;
	--grey: #25252c;
	--dark: #fbfbfb;
	--success: #388e3c;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}
.sidebar.close img.smallLogo {
	height: 45px;
}
.sidebar.close a.logo {
	padding-top: 20px;
	padding-left: 10px;
	transition: ease;
}
.bx {
	font-size: 1.7rem;
}

a {
	text-decoration: none;
}

li {
	list-style: none;
}

html {
	overflow-x: hidden;
}

body {
	background: var(--grey);
	overflow-x: hidden;
}

.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	background: var(--light);
	width: 210px;
	height: 100%;
	z-index: 2;
	overflow-x: hidden;
	scrollbar-width: none;
	transition: all 0.3s ease;
}

.sidebar::-webkit-scrollbar {
	display: none;
}

.sidebar.close {
	width: 60px;
}

.sidebar .logo {
	padding-top: 25px;
	display: flex;
	align-items: center;
	/* color: var(--primary); */
	z-index: 500;
	box-sizing: content-box;
	justify-content: center;
	align-items: center;
}

.sidebar .logo img {
	height: 75px;
}

/* .sidebar .logo .logo-name span {
	color: var(--dark);
} */

.sidebar .logo .bx {
	min-width: 60px;
	display: flex;
	justify-content: center;
	font-size: 2.2rem;
}

.sidebar .side-menu {
	width: 100%;
	height: 80vh;
	margin-top: 80px;
}

.sidebar .side-menu li {
	height: 60px;
	background: transparent;
	margin-left: 10px;
	border-radius: 48px 0 0 48px;
	padding: 5px;
}

.sidebar .side-menu li.active {
	background: var(--grey);
	position: relative;
}

.sidebar .side-menu li.active::before {
	content: "";
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	top: -40px;
	right: 0;
	box-shadow: 20px 20px 0 var(--grey);
	z-index: -1;
}

.sidebar .side-menu li.active::after {
	content: "";
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	bottom: -40px;
	right: 0;
	box-shadow: 20px -20px 0 var(--grey);
	z-index: -1;
}

.sidebar .side-menu li a {
	width: 100%;
	height: 100%;
	background: var(--light);
	display: flex;
	align-items: center;
	border-radius: 48px;
	font-size: 20px;
	font-weight: 900;
	color: var(--dark);
	white-space: nowrap;
	overflow-x: hidden;
	transition: all 0.3s ease;
	cursor: pointer;
}

.sidebar .side-menu li a:hover {
	color: var(--red);
}
.sidebar .side-menu li.active a {
	color: var(--red);
}

.sidebar.close .side-menu li a {
	width: calc(48px - (4px * 2));
	transition: all 0.3s ease;
}

.sidebar .side-menu li a .bx {
	min-width: calc(60px - ((4px + 6px) * 2));
	display: flex;
	font-size: 1.6rem;
	justify-content: center;
}

#components-layout-demo-custom-trigger .trigger:hover {
	color: white;
}

.content {
	position: relative;
	width: calc(100% - 210px);
	height: 100%;
	left: 210px;
	transition: all 0.3s ease;
}

.sidebar.close ~ .content {
	width: calc(100% - 60px);
	left: 60px;
}

.content nav {
	height: 65px;
	background: var(--light);
	padding: 15px 20px 20px 20px;
	display: flex;
	align-items: center;
	grid-gap: 15px;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 1000;
	margin-bottom: 20px;
}

.content nav::before {
	content: "";
	position: absolute;
	width: 40px;
	height: 40px;
	bottom: -40px;
	left: 0;
	border-radius: 50%;
	/* box-shadow: -20px -20px 0 var(--light); */
}
.content nav a {
	color: var(--dark);
	font-size: 20px;
	font-weight: 900;
}

nav li {
	width: 120px;
	background: transparent;
	border-radius: 20px 20px 0 0;
	padding: 5px;
	text-align: center;
	margin-top: 23px;
	overflow-wrap: break-word;
}

nav li.active {
	background: var(--grey);
	position: relative;
}

nav li.active::before {
	content: "";
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	right: 120px;
	top: 10px;
	box-shadow: 20px 20px 0 var(--grey);
	z-index: -1;
}

nav li.active::after {
	content: "";
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	right: -40px;
	top: 10px;
	box-shadow: -20px 20px 0 var(--grey);
	z-index: -1;
}

nav li a .logout {
	width: auto;
	white-space: nowrap;
}
nav li a {
	width: 110px;
	height: 100%;
	background: var(--light);
	display: flex;
	justify-content: center;
	border-radius: 48px;
	font-size: 20px;
	font-weight: 900;
	color: var(--dark);
	/* overflow-x: hidden; */
	transition: all 0.3s ease;
	padding: 10px;
	cursor: pointer;
	overflow-wrap: break-word;
}
.new-table {
	white-space: nowrap;
}
.how-to {
	white-space: nowrap;
}
nav li a:hover {
	color: var(--red);
}
nav li.active a {
	color: var(--red);
}

nav .scroll-div {
	display: flex;
	overflow-x: auto;
	grid-gap: 15px;
	padding-left: 15px;
	padding-right: 15px;
}
.scroll-div::-webkit-scrollbar {
	display: none;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
nav .nav-section {
	display: flex;
	grid-gap: 15px;
	margin-right: 15px;
}

.content .menu-icon::before {
	content: "";
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	top: 65px;
	left: 0;
	/* right: -50px; */
	box-shadow: -20px -20px 0 var(--light);
	/* z-index: 1; */
}
.content .new-table {
	width: 150px;
}

.content .logout {
	display: flex;
	align-items: center;
	margin-left: auto;
}
.content .how-to {
	display: flex;
	align-items: center;
}

.bx {
	margin-right: 8px;
	/* margin-left: 5px; */
}

.content .menu-icon {
	color: white;
	padding-right: 20px;
	padding-top: 10px;
	padding-right: 10px;
}

.sidebar .side-menu div.has-border {
	border-top: 1px solid rgba(255, 255, 255);
}

.sidebar .side-menu li.delete {
	position: absolute;
	bottom: 0;
	color: var(--red);
}
/* #components-layout-demo-custom-trigger .logo {
	height: 52px;
	margin: 16px;
	background: rgba(255, 255, 255, 0.3);
} */

/* .site-layout {
	margin-left: auto;
} */

.main {
	margin-right: 20px;
}
.phone-logo {
	display: none;
}

.phone-logout {
	display: none;
}
.nav-spacer {
	display: none;
}
.ant-layout .ant-layout-sider {
	background-color: #242526;
}
.ant-layout-header {
	background-color: #242526;
}

.ant-menu-dark {
	background-color: #242526;
}

.ant-layout {
	background-color: #242526;
}

/* .trigger {
	padding: 0 24px;
	font-size: 18px;
	line-height: 64px;
	color: #b4d3b2;
	cursor: pointer;
	transition: color 0.3s;
} */

@media screen and (max-width: 768px) {
	.sidebar {
		width: 200px;
	}

	.content {
		width: calc(100% - 60px);
		left: 200px;
	}
}

@media screen and (max-width: 897px) {
	body {
		background-color: white;
	}
	.sidebar.close {
		width: 100%;
		top: -100%;
		transition: all 0.5s ease 0s;
	}

	.sidebar a.logo {
		display: none;
	}
	.sidebar .side-menu {
		margin-top: 0;
		height: 100%;
	}
	nav {
		z-index: 1;
	}

	.content nav {
		margin-bottom: 5px;
	}
	.phone-logout {
		display: block;
	}
	nav li.active::before {
		display: none;
	}
	nav li.active.hide::before {
		display: none;
	}
	nav li.active.hide::after {
		display: none;
	}
	nav li.active::after {
		display: none;
	}
	nav li {
		width: 80px;
		background: transparent;
		border-radius: 20px;
		padding: 5px;
		text-align: center;
		margin-top: 30px;
	}

	nav li.nav-table-item a {
		font-size: 12px;
	}
	.sidebar {
		display: block;
		width: 100%;
		height: 300px;
		border-radius: 0 0 30px 30px;
		top: 65px;
		transition: all 0.3s ease;
		z-index: 6;
		/* position: relative; */
	}
	.content {
		width: 100%;
		position: absolute;
		/* left: 200px; */
		/* width: fill; */
	}

	.main {
		margin: 0;
	}
	.sidebar.close ~ .content {
		position: absolute;
		width: 100%;
		left: 0;
	}
	.sidebar ~ .content {
		position: absolute;
		width: 100%;
		left: 0;
	}

	div.menu-icon::before {
		display: none;
	}

	.content a.phone-logo-container {
		float: none;
		position: absolute;
		top: 50%;
		left: 60px;
		transform: translate(-50%, -50%);
	}
	img.phone-logo {
		display: block;
		height: 50px;
	}
	.logout {
		display: none;
	}
	.nav-spacer {
		display: block;
		width: 0;
		margin-left: 25%;
	}
	div.menu-icon {
		float: none;
		position: absolute;
		top: 50%;
		left: 95%;
		transform: translate(-50%, -50%);
	}
	.sidebar.close .side-menu li a {
		display: none;
	}

	.content li.logout {
		display: none;
	}
}
