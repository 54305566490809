:root {
	--green: #b4d3b2;
	--red: #d4371c;
	--darkRed: #811b09;
	--lightGray: #55575a;
	--darkGray: #3a3b3c;
	--darkestGray: #242526;
	--light: #181a1e;
	--grey: #25252c;
	--dark: #fbfbfb;
	--success: #388e3c;
}

#contact-header {
	color: white;
	font-size: 50px;
	/* margin-left: 2em; */
	margin-bottom: 20px;
	margin-top: 0;
}
#contact-container {
	color: white;
	font-size: 50px;
	padding-top: 20px;
	padding: 40px;
	background-color: var(--light);
	border-radius: 20px;
	height: calc(100vh - 15vh);
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	padding-left: 60px;
	margin-left: 20px;
	margin-right: 20px;
}
input {
	padding: 10px;
	font-size: 18px;
	margin-top: 15px;
	border-radius: 20px;
}
textarea {
	margin-top: 15px;
	padding: 10px;
	font-size: 18px;
	border-radius: 20px;
	width: 500px;
	height: 200px;
}
input:focus-visible {
	outline: var(--red) auto 1px;
}
textarea:focus-visible {
	outline: var(--red) auto 1px;
}

#contact-item {
	font-size: 20px;
}

button {
	margin-top: 15px;
	padding: 10px;
	font-size: 18px;
	margin-bottom: 10px;
	border: none;
	color: white;
	border-radius: 5em;
	background-color: var(--red);
	cursor: pointer;
}

.email-error {
	color: var(--red);
	font-size: 20px;
	margin-top: 25px;
	padding-left: 20px;
	vertical-align: center;
}
.email-confirmation {
	color: green;
	font-size: 20px;
	margin-top: 25px;
	padding-left: 20px;
}
#contact-button {
	display: flex;
	flex-direction: row;
}
@media screen and (max-width: 768px) {
	textarea {
		width: fill;
	}
	input {
		width: fill;
	}
	#contact-button {
		flex-direction: column;
	}
	#contact-container {
		padding-left: 40px;
	}
	.email-confirmation {
		padding-left: 0;
		margin-top: 10px;
		text-align: center;
	}
	.email-error {
		padding-left: 0;
		margin-top: 10px;

		text-align: center;
	}
}
