:root {
	--green: #b4d3b2;
	--red: #d4371c;
	--darkRed: #811b09;
	--lightGray: #25252c;
	--darkGray: #363646;
}
.comment {
	display: flex;
	flex-direction: column;
	justify-content: start;
	max-height: 150px;
	padding-left: 20px;
	padding-top: 20px;
}
.comment .active {
	background-color: #3a3b3c;
	color: white;
}
.comment-header {
	display: flex;
	justify-content: start;
	text-align: left;
}
.comment-author {
	padding-right: 10px;
}
.comment-date {
	color: gray;
}
.comment-body {
	max-width: 700px;
	text-align: left;
	font-weight: 400;
	column-gap: 0.2em;
	padding-left: 20px;
	padding-top: 5px;
}

.comment-reply {
	margin-bottom: 0px;
	width: 75px;
	font-weight: 900px;
	font-size: 12px;
	background-color: #3a3b3c;
}
