:root {
	--green: #b4d3b2;
	--red: #d4371c;
	--darkRed: #811b09;
	--lightGray: #25252c;
	--darkGray: #363646;
}

.ant-upload-wrapper
	.ant-upload-list
	.ant-upload-list-item
	.ant-upload-list-item-name {
	display: none;
}

.minitable .ant-table .ant-table-title + .ant-table-container table {
	height: 365px;
	max-width: 300px;
	/* white-space: wrap; */
}

.minitable .ant-upload-list .ant-upload-list-item-container {
	height: 0;
	transition: none;
}
.minitable .ant-table {
	background-color: var(--darkRed);
	border-radius: 48px;
}

.minitable .ant-table-title {
	padding-bottom: 0;
}
.minitable .ant-table-title {
	text-align: center;
	font-size: 20px;
}

.minitable div.ant-table-title {
	background-color: #811b09;
	display: flex;
	justify-content: center;
	color: white;
	font-weight: 900;
	font-size: 20px;
	border-radius: 48px;
	text-align: center;
}
.minitable .ant-table-thead > tr > td {
	background-color: var(--darkRed);
}

.minitable .ant-table .ant-table-container .ant-table-content table,
th.ant-table-cell,
td.ant-table-cell {
	border: none;
	border-collapse: collapse;
}

.minitable .ant-table-tbody > tr.ant-table-row:hover > td {
	background-color: #55575a;
}
.minitable .ant-table-tbody > tr.ant-table-row {
	max-height: 57px;
}

.minitable
	.ant-table
	.ant-table-container
	.ant-table-content
	table
	thead.ant-table-thead
	.ant-table-cell {
	background-color: var(--darkRed);
	text-align: center;
	border: none;
}

.minitable .ant-table-thead > tr > th {
	background-color: var(--darkRed);
	color: white;
	font-weight: 900;
	text-align: center;
}

.minitable .ant-table .ant-table-container {
	background-color: var(--darkGray);
	text-align: center;
	border: none;
}
.minitable .ant-table .ant-table-container .ant-table-content table {
	background-color: var(--darkGray);
	text-align: center;
	border: none;
}

.minitable .ant-table .ant-table-header {
	background-color: var(--darkRed);
	border-radius: 0;
}

.minitable .ant-table-tbody > tr > th,
.minitable .ant-table-tbody > tr > td {
	background-color: var(--darkGray);
	color: white;
	font-weight: 600;
}

.minitable .ant-table-tbody > tr.ant-table-placeholder:hover > td {
	background-color: #55575a;
}
